import React from 'react';
import tw from 'tailwind-styled-components';
import { PreFooters, PreFooterSectionItem } from '../../../types';
import { PageVariables } from '../../../services/pageVariables';
import { textCompiler } from '../../../services/textCompiler';

interface PreFooterSectionProps {
  variables?: PageVariables;
  isFullScreen?: boolean;
  data: PreFooters;
}

interface PreFooterProps {
  title: string;
  items: PreFooterSectionItem[];
  variables?: PageVariables;
  isFullScreen?: boolean;
}

function PreFooter(props: PreFooterProps): JSX.Element {
  const { title, items, variables } = props;

  return (
    <Row>
      <Title>{textCompiler(title, variables)}</Title>
      <LinksContainer>
        {items.map((item, i) => (
          <Link key={i} href={item.url}>
            {textCompiler(`{type} à ${item.name}`, variables)}
          </Link>
        ))}
      </LinksContainer>
    </Row>
  );
}

export function PreFooterSection(props: PreFooterSectionProps): JSX.Element {
  const { data, variables } = props;

  return (
    <Main>
      <PreFooter
        title={'Recherches associées à {type} {city}'}
        items={data.proximity.items}
        variables={variables}
      />
      <PreFooter
        title={'Recherches fréquentes à {type} {city}'}
        items={data.bestResults.items}
        variables={variables}
      />
    </Main>
  );
}

const Main = tw.div`
  mt-lg
  mx-sm
  lg:mt-0
  lg:mx-0
  lm:px-0 
  px-5
  grid grid-cols-2
`;

const Row = tw.div`
  py-8
  px-8
  border-t
  col-span-1
`;

const Title = tw.p`
  text-base
`;

const LinksContainer = tw.div`
  grid
  lg:grid-cols-2
  grid-cols-1
`;

const Link = tw.a`
  text-sm
  mt-4
  text-darkGrey
  hover:underline 
  hover:text-blue
  `;
