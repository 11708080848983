import { useState } from "react";
import tw from "tailwind-styled-components";
import { Button, Dropdown, Image, Logo } from "../../../components";
import { PageVariables } from "../../../services/pageVariables";
import { getStrapiMediaUrl } from "../../../services/strapiApi";
import { LinksSectionLink, NavigationBarType } from "../../../types";

interface NavigationBarProps {
  variables?: PageVariables;
  isFullScreen?: boolean;
  data: NavigationBarType;
  overrideLogoUrl?: string;
}

const externalLinks = {
  register: "/webapp/#/auth/subscribe",
  login: "/webapp/#/auth/",
};

export function NavigationBar(props: NavigationBarProps): JSX.Element {
  const { data, isFullScreen, overrideLogoUrl } = props;
  const { logo, links, bookButton } = data;

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <Main>
      <LogoContainer>
        <LogoButton href={overrideLogoUrl ?? "/"}>
          <LogoStyled
            src={getStrapiMediaUrl(logo?.data?.attributes)}
            alt={logo?.data?.attributes.name}
          />
        </LogoButton>
      </LogoContainer>
      <LinksContainer>
        {links.map((link: LinksSectionLink) => (
          <NavLink
            target={link.newWindow ? "_blank" : ""}
            rel={link.newWindow ? "noopener noreferrer" : ""}
            key={link.id}
            href={link.url}
          >
            {link.text}
          </NavLink>
        ))}
      </LinksContainer>
      <ActionsContainer>
        {isFullScreen && bookButton && (
          <BookContainer>
            {
              <BookButton
                target={bookButton.newWindow ? "_blank" : ""}
                href={bookButton.destination}
                color={"green"}
              >
                {bookButton.label}
              </BookButton>
            }
          </BookContainer>
        )}
        <NetworkContainer>
          <LocaleDropdown
            options={[
              { label: "Français", url: "/fr" },
              // { label: 'English', url: '/en' },
            ]}
            optionsWidth={"22"}
          >
            <Image
              className="self-center w-5 h-5"
              src="/network.svg"
              alt="chose language"
            />
          </LocaleDropdown>
        </NetworkContainer>
        <BurgerAndUserDropdown
          options={[
            { label: "Inscription", url: externalLinks.register },
            { label: "Connexion", url: externalLinks.login },
          ]}
        >
          <BurgerAndUserContainer onClick={() => setIsMobileMenuOpen(true)}>
            <Image className="self-center w-5 h-5" src="/menu.svg" alt="menu" />
            <Image className="self-center w-8 h-8" src="/user.svg" alt="user" />
          </BurgerAndUserContainer>
        </BurgerAndUserDropdown>
      </ActionsContainer>
      <MobileMenu $open={isMobileMenuOpen}>
        <MobileMenuBar>
          <LogoContainer>
            <LogoStyled
              src={getStrapiMediaUrl(logo?.data?.attributes)}
              alt={logo?.data?.attributes.name}
            />
          </LogoContainer>
          <MenuCloseContainer onClick={() => setIsMobileMenuOpen(false)}>
            <MenuClose src="/close.svg" alt="close" />
          </MenuCloseContainer>
        </MobileMenuBar>
        <LinksContainerMobile>
          {links.map((link: LinksSectionLink) => (
            <NavLinkMobile href={link.url} key={link.id}>
              <NavLinkMobileContainer>
                {link.text}
                <Image
                  className="self-center w-3 h-3"
                  src="/right-arrow.svg"
                  alt="right"
                />
              </NavLinkMobileContainer>
            </NavLinkMobile>
          ))}
          <ExternalLinksContainer>
            <NavLinkSmallMobile href={externalLinks.register}>
              <NavLinkMobileContainer>
                Inscription
                <Image
                  className="self-center w-3 h-3"
                  src="/right-arrow.svg"
                  alt="inscription"
                />
              </NavLinkMobileContainer>
            </NavLinkSmallMobile>
            <NavLinkSmallMobile href={externalLinks.login}>
              <NavLinkMobileContainer>
                Connexion
                <Image
                  className="self-center w-3 h-3"
                  src="/right-arrow.svg"
                  alt="connexion"
                />
              </NavLinkMobileContainer>
            </NavLinkSmallMobile>
          </ExternalLinksContainer>
          <OptionsContainer>
            <LocaleDropdownNoCenter
              options={[
                { label: "Français", url: "/fr" },
                // { label: 'English', url: '/en' },
              ]}
              optionsWidth={"full"}
            >
              <OptionLanguageRow>
                <Image
                  className="self-center w-5 h-5"
                  src="/network.svg"
                  alt="language"
                />
                <OptionLanguageText>Langage</OptionLanguageText>
              </OptionLanguageRow>
            </LocaleDropdownNoCenter>
          </OptionsContainer>
        </LinksContainerMobile>
      </MobileMenu>
    </Main>
  );
}

const Main = tw.div`
  w-full
  shadow-navbar
  lg:flex
  lg:flex-row
  grid
  grid-cols-12
  justify-between
  content-center
  h-20
`;

const BookContainer = tw.div`
  bg-white
  fixed
  bottom-0
  left-0
  right-0
  p-4
  w-full
  lg2:h-fit
  lg2:w-fit
  lg2:p-0
  lg2:static
  z-50
`;

const BookButton = tw(Button)`
  mr-0
  w-full
  lg2:w-fit
  lg2:mr-6
  z-50
`;

const LogoContainer = tw.div`
  lg:flex
  col-span-6
  lg:pl-8
  pl-4
  grid
  content-center
`;

const LinksContainer = tw.div`
hidden
col-span-5
lg:flex
lg:flex-row
lg:justify-center
lg:content-center
font-sans
`;

const ActionsContainer = tw.div`
lg:col-span-4
lg:flex
lg:flex-row
lg:justify-end
lg:pr-4
lg:py-4
col-span-6
grid
grid-flow-col
gap-2
content-center
`;

const LogoButton = tw.a`
self-center	`;

const LogoStyled = tw(Logo)`
w-28
lg:w-32
h-auto
lg:h-auto
lg:self-center
`;

const NavLink = tw.a`
2xl:ml-6
ml-4
lg:first:ml-0
lg:grid
lg:content-center
`;

const NetworkContainer = tw.div`
col-start-6
hidden
lg:flex
lg:mr-4
content-center
justify-center
`;

const BurgerAndUserContainer = tw.div`
flex
flex-row
justify-between 
w-full
`;

const BurgerAndUserDropdown = tw(Dropdown)`
col-start-5
border
border-gray-400
rounded-full
px-2
pl-3
py-1
flex
flex-row
justify-between
w-20
z-10
`;

const LocaleDropdown = tw(Dropdown)`
flex
content-center
justify-center
cursor-pointer
`;

const LocaleDropdownNoCenter = tw(Dropdown)`
flex
cursor-pointer
`;

const MobileMenu = tw.div`
${(props: { $open: boolean }) => (props.$open ? "fixed" : "hidden")}
z-20
bg-white
h-screen
w-screen
lg:hidden
`;

const MenuCloseContainer = tw.div`
w-14
h-14
grid
justify-center
content-center
rounded-full
border
m-auto
mr-0
`;

const MenuClose = tw(Image)`
w-10
h-10
`;

const MobileMenuBar = tw.div`
pr-4
flex
flex-row
w-full
justify-between
content-center
h-20
shadow-navbar
`;

const LinksContainerMobile = tw.div`
flex
flex-col
pt-8
`;

const NavLinkMobileContainer = tw.div`
flex
flex-row
justify-between
px-4
`;

const NavLinkMobile = tw.a`
mb-4
text-smallTitle
font-bold
`;

const NavLinkSmallMobile = tw.a`
mb-4
text-smallTitle
px-4
`;

const ExternalLinksContainer = tw.div`
mt-16
`;

const OptionsContainer = tw.div`
mt-16
grid
grid-cols-1
px-4
`;

const OptionLanguageRow = tw.div`
col-span-1
flex flex-row content-center
`;

const OptionLanguageText = tw.span`
font-semibold
ml-2
`;
