/* eslint-disable indent */
import dynamic from "next/dynamic";
import React, { useEffect, useRef, useState } from "react";
import tw from "tailwind-styled-components";
import { Button } from "../Button";
import { useScrollPosition } from "../../hooks/useScrollPosition";
import { useRouter } from "next/router";
import { encode } from "js-base64";
import "@allocab/web-booker-widget/dist/main.css";

type WebBookerWidget = {
  onSuccess: any;
  onError: any;
  locale: string | undefined;
  target: string;
  cms: boolean;
};

const BookerWidget = dynamic<WebBookerWidget>(
  () => import("@allocab/web-booker-widget"),
  {
    ssr: false,
  }
);

const IFRAME_ID = "web-booker-widget";

const REINSURANCE_ICONS = [
  "orange-star.svg",
  "orange-map-pin.svg",
  "orange-heart.svg",
  "orange-clock.svg",
];

export interface IFrameBookerProps {
  reassurances?: string[];
  hasBooker: boolean;
}

function resolveReinsuranceIcon(index: number, reassurances: string[]): string {
  if (index < REINSURANCE_ICONS.length) {
    return REINSURANCE_ICONS[0];
  }
  return REINSURANCE_ICONS[index];
}

export function IFrameBooker(props: IFrameBookerProps): JSX.Element {
  const router = useRouter();
  const { locale } = router;
  const { reassurances, hasBooker } = props;
  const scrollPosition = useScrollPosition();
  const [bookerHeight, setBookerHeight] = useState<number | null>(null);
  const [ready, setReady] = useState<boolean | boolean>(false);
  const [clearQuotations, setClearQuotations] = useState(false);
  const [clearSessions, setClearSessions] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setBookerHeight((ref?.current as any)?.clientHeight);
  });

  const onSuccess = (result: any, transit: any) => {
    const bession = {
      quotations: result.data,
      transit,
      step: 2,
    };
    const redirection = `/web-booker/booking-details/${
      result.data.id
    }?bsession=${encode(JSON.stringify(bession))}`;
    window.location.href = redirection;
  };
  const onError = (error: any) => {
    console.log("error", error);
  };

  function getClassName() {
    let className = "quotation-form";
    if (!ready) {
      className += " loading";
    }
    return className;
  }

  let environment = "";
  if (process.env.NEXT_PUBLIC_DEPLOY === "staging") {
    environment = "development";
  } else if (process.env.NEXT_PUBLIC_DEPLOY === "production") {
    environment = "production";
  } else {
    environment = "development";
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setReady(true);
  }, []);

  return (
    <>
      <Main ref={ref} id={IFRAME_ID}>
        {hasBooker && (
          <>
            <div className={getClassName() + " bg-white md:p-2 md:pt-3"}>
              {ready ? (
                <BookerWidget
                  cms={true}
                  onSuccess={onSuccess}
                  onError={onError}
                  locale={/(^|\/)en(\/|$)/.test(router.asPath) ? "en" : locale}
                  target={environment} // development/production
                />
              ) : (
                <></>
              )}
            </div>
            <Reinsurances>
              {reassurances &&
                reassurances.map((reinsurance, i) => (
                  <Reinsurance key={i}>
                    <Icon src={`/${resolveReinsuranceIcon(i, reassurances)}`} />
                    <ReinsuranceText>{reinsurance}</ReinsuranceText>
                  </Reinsurance>
                ))}
            </Reinsurances>
          </>
        )}
      </Main>
      <RelativeContainer>
        {bookerHeight && scrollPosition > bookerHeight ? (
          <BackToTopButtonContainer>
            <BackToTopButton color={"green"} href={`#${IFRAME_ID}`}>
              {hasBooker ? "Consulter les prix" : "Commander une course green"}
            </BackToTopButton>
          </BackToTopButtonContainer>
        ) : (
          <></>
        )}
      </RelativeContainer>
    </>
  );
}

const Main = tw.div`
  sticky
  top-0
  lg:top-10
  lg:px-6
  lg:pt-12
`;

const RelativeContainer = tw.div`
  relative
  z-40
`;

const Iframe = tw.iframe`
  w-full
  h-144
`;

const Reinsurances = tw.div`
grid grid-cols-2 w-full gap-y-6 gap-x-2 bg-white p-2
`;

const Reinsurance = tw.div`
  col-span-1
  flex
  content-center
  flex-row
`;

const Icon = tw.img`
  max-w-[30px]
  max-h-[30px]
`;

const ReinsuranceText = tw.span`
ml-2
`;

const BackToTopButtonContainer = tw.div`
fixed
bottom-0
left-0
w-screen
bg-white
p-4
lg:hidden
border-t
z-50
`;

const BackToTopButton = tw(Button)`
w-full
mr-0
hover:opacity-80
`;
